import React from 'react'
// Types
import { ResourcesLandingProps } from '../../../types/resources'
// Components
import { Flex, Box, Text, Image } from '../../../atoms'

type ResourcesHeroProps = Pick<ResourcesLandingProps, 'headline' | 'description' | 'backgroundImage'>

const ResourcesHero: React.FC<ResourcesHeroProps> = ({ headline, description, backgroundImage }) => {
  return (
    <Flex
      position={'relative'}
      minHeight={340}
      alignItems={'center'}
      px={[6, 8, 10]}
      py={[8, 10]}
      borderRadius={'large'}
      bg={'purple'}
    >
      {backgroundImage && (
        <Box
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          borderRadius={'large'}
          overflow={'hidden'}
          zIndex={1}
        >
          <Image
            alt={''}
            draggable={'false'}
            image={backgroundImage.gatsbyImageData}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      )}
      <Box position={'relative'} zIndex={2}>
        <Text
          as={'h1'}
          fontSize={['32px', '40px', '56px']}
          lineHeight={['40px', '48px', '64px']}
          fontWeight={'semi'}
          fontFamily={'hoves'}
          color={'white'}
        >
          {headline}
        </Text>
        {!!description && (
          <Box mt={4}>
            <Text color={'white'} whiteSpace={'pre-line'}>
              {description}
            </Text>
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default ResourcesHero
