import React from 'react'
// Types
import { ResourceCardProps, ResourceCategoryProps, ResourcesLandingProps } from '../../../types/resources'
import { PaginationContextProps } from '../../../types/pages'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Flex } from '../../../atoms'
import { ResourcesHero, PaginatedResouces } from '../../../components/resources'
import { CategoryLink } from '../../../components/commons'

interface ResourcesViewProps {
  isAll?: boolean
  landing: ResourcesLandingProps
  categories: ResourceCategoryProps[]
  resources: ResourceCardProps[]
  pagination: PaginationContextProps
}

const ResourcesView: React.FC<ResourcesViewProps> = ({ isAll = false, landing, categories, resources, pagination }) => {
  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <Box pt={[100, 120, 140]}>
            <ResourcesHero
              headline={landing.headline}
              description={landing.description}
              backgroundImage={landing.backgroundImage}
            />
          </Box>

          <Flex mt={[6, 8, 10]} alignItems={'center'} flexWrap={'wrap'}>
            <Box mt={3} mr={[6, 7, 8]}>
              <CategoryLink name={landing.allResourcesLabel} pageInfo={landing.pageInfo} partiallyActive={isAll} />
            </Box>
            {categories.map((c, i) => (
              <Box key={c.id} mt={3} mr={i + 1 < categories.length ? [5, 6, 7] : undefined}>
                <CategoryLink {...c} />
              </Box>
            ))}
          </Flex>

          <PaginatedResouces
            resources={resources}
            downloadLabel={landing.downloadResourceLabel}
            totalPages={pagination.totalPages}
            currentPage={pagination.currentPage}
            prevLabel={landing.paginationPreviousLabel}
            previousPagePath={pagination.previousPagePath}
            nextLabel={landing.paginationNextLabel}
            nextPagePath={pagination.nextPagePath}
          />
        </Col>
      </Row>
    </Grid>
  )
}

export default ResourcesView
