import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PaginationContextProps, PublicationProps } from '../../types/pages'
import { ResourceCardProps, ResourceCategoryProps, ResourcesLandingProps } from '../../types/resources'
// Components
import { Layout, Seo } from '../../components/commons'
import { ResourcesView } from '../../views/resources'

interface DataQuery {
  resourcesLanding: ResourcesLandingProps
  category: ResourceCategoryProps & {
    locale: string
    meta: PublicationProps
  }
  categoriesList: {
    nodes: ResourceCategoryProps[]
  }
  resourcesList: {
    nodes: ResourceCardProps[]
  }
}

type PageContext = PaginationContextProps & { categoryId: string }

const Resources: React.FC<PageProps<DataQuery, PageContext>> = ({ pageContext, data }) => {
  const {
    resourcesLanding: { ...resourcesData },
    category: { locale, meta, pageInfo },
    categoriesList: { nodes: categories },
    resourcesList: { nodes: resources },
  } = data

  return (
    <Layout headerDark={resourcesData.pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />
      <ResourcesView
        isAll={false}
        landing={resourcesData}
        categories={categories}
        resources={resources}
        pagination={pageContext}
      />
    </Layout>
  )
}

export const query = graphql`
  query ResourceCategoryQuery($categoryId: String!, $skip: Int!, $limit: Int!) {
    resourcesLanding: datoCmsResourcesLanding {
      pageInfo {
        ...pageInfoFragment
      }
      headline
      description
      allResourcesLabel
      downloadResourceLabel
      paginationNextLabel
      paginationPreviousLabel
      backgroundImage {
        alt
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    category: datoCmsResourceCategory(id: { eq: $categoryId }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      ...resourceCategoryFragment
    }
    categoriesList: allDatoCmsResourceCategory(sort: { fields: name, order: DESC }) {
      nodes {
        ...resourceCategoryFragment
      }
    }
    resourcesList: allDatoCmsResource(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { category: { id: { eq: $categoryId } } }
    ) {
      nodes {
        ...resourceCardFragment
      }
    }
  }
`

export default Resources
