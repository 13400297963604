import React from 'react'
// Types
import { ResourceCardProps } from '../../../types/resources'
// Components
import { Link } from 'gatsby'
import { Box, Text, Image, Button } from '../../../atoms'
import { ResourceCardImageWrapper, ResourceCardTitle, ResourceCardDescription } from './ResourceCard.styled'

const ResourceCard: React.FC<ResourceCardProps & { downloadLabel?: string }> = ({
  title,
  thumbnail,
  category,
  description,
  pageInfo,
  comingSoon,
  downloadLabel = 'Download now',
}) => {
  return (
    <Box>
      <Box borderRadius={'regular'} overflow={'hidden'} zIndex={1}>
        {comingSoon ? (
          <ResourceCardImageWrapper position={'relative'}>
            <Image
              draggable={false}
              alt={thumbnail.alt || title}
              image={thumbnail.gatsbyImageData}
              style={{
                borderRadius: '16px',
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              imgStyle={{ borderRadius: '16px' }}
            />
          </ResourceCardImageWrapper>
        ) : (
          <Link to={`/${pageInfo.slug}`} style={{ display: 'block' }}>
            <ResourceCardImageWrapper position={'relative'}>
              <Image
                draggable={false}
                alt={thumbnail.alt || title}
                image={thumbnail.gatsbyImageData}
                style={{
                  borderRadius: '16px',
                  overflow: 'hidden',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                imgStyle={{ borderRadius: '16px' }}
              />
            </ResourceCardImageWrapper>
          </Link>
        )}
      </Box>
      <Box mt={5}>
        {category && (
          <Box mb={3}>
            <Link to={`/${category.pageInfo.slug}`}>
              <Box
                display={'inline-block'}
                bg={category.color.hex || 'purpleBlue'}
                px={3}
                py={'4px'}
                borderRadius={'circle'}
              >
                <Text
                  as={'span'}
                  color={'white'}
                  fontSize={13}
                  fontWeight={'semi'}
                  lineHeight={1.2}
                  textTransform={'uppercase'}
                >
                  {category.name}
                </Text>
              </Box>
            </Link>
          </Box>
        )}
        {comingSoon ? (
          <Box>
            <Box mb={2} minHeight={54}>
              <ResourceCardTitle fontWeight={'semi'} lineHeight={1.5}>
                {title}
              </ResourceCardTitle>
            </Box>
            <Box minHeight={84}>
              {!!description && (
                <ResourceCardDescription fontSize={2} lineHeight={'28px'}>
                  {description}
                </ResourceCardDescription>
              )}
            </Box>
            <Box mt={4}>
              <Text fontWeight={'semi'} lineHeight={1.5} color={'dark'} opacity={0.3}>
                Coming soon
              </Text>
            </Box>
          </Box>
        ) : (
          <Link to={`/${pageInfo.slug}`} style={{ display: 'block' }}>
            <Box mb={2} minHeight={54}>
              <ResourceCardTitle fontWeight={'semi'} lineHeight={1.5}>
                {title}
              </ResourceCardTitle>
            </Box>
            <Box minHeight={84}>
              {!!description && (
                <ResourceCardDescription fontSize={2} lineHeight={'28px'}>
                  {description}
                </ResourceCardDescription>
              )}
            </Box>
            <Box mt={4}>
              <Button buttonType={'text'} color={category.color.hex || 'purpleBlue'}>
                {downloadLabel}
              </Button>
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  )
}

export default ResourceCard
