import React, { PropsWithChildren, useMemo } from 'react'
// Types
import { ResourceViewProps } from '../../../types/resources'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Image } from '../../../atoms'
import { ContentMd, HubspotForm } from '../../../components/commons'
import { BoxWithDot } from './ResourceView.styled'

const ResourceView: React.FC<ResourceViewProps> = (props) => {
  const { title, cover, description, content, hubspotForm } = props

  const renderers = useMemo(() => {
    return {
      li: (props: PropsWithChildren<any>) => {
        return (
          <BoxWithDot as={'li'} position={'relative'} my={4} dotColor={'purpleBlue'} ordered={props.ordered}>
            <Text as={'span'} fontSize={2} lineHeight={1.5} {...props} color={'dark'} />
          </BoxWithDot>
        )
      },
    }
  }, [])

  return (
    <Grid>
      <Row>
        <Col xs={12} md={7}>
          <Flex mb={[7, 0]} pr={[0, 0, 6]} flexWrap={['wrap', 'nowrap']}>
            {cover && (
              <Box flex={['0 0 100%', '0 0 180px']} mr={[0, 36]} mb={[6, 0]}>
                <Image draggable={false} alt={cover.alt || title} image={cover.gatsbyImageData} />
              </Box>
            )}
            <Box flex={['0 0 100%', '1']}>
              <Heading as={'h1'} fontSize={['28px', '28px', '28px']} lineHeight={['34px', '34px', '34px']}>
                {title}
              </Heading>
              {!!description && (
                <Box mt={5}>
                  <Text fontSize={2} whiteSpace={'pre-line'}>
                    {description}
                  </Text>
                </Box>
              )}
            </Box>
          </Flex>
          {!!content && (
            <Box mt={[8, 9, 10]} pr={[0, 0, 6]}>
              <ContentMd content={content} renderers={renderers} />
            </Box>
          )}
        </Col>
        <Col xs={12} md={5}>
          {!!hubspotForm && (
            <Box mx={'-10px'} mt={[8, 0]}>
              <HubspotForm region={hubspotForm.region} portalId={hubspotForm.portalId} formId={hubspotForm.formId} />
            </Box>
          )}
        </Col>
      </Row>
    </Grid>
  )
}

export default ResourceView
