import React from 'react'
import { Box, BoxProps, Text, TextProps } from '../../../atoms'
import styled from 'styled-components'

export const ResourceCardImageWrapper: React.FC<BoxProps> = styled(Box)<BoxProps>`
  padding-top: calc(100% / 1.566);
`

export const ResourceCardTitle: React.FC<TextProps> = styled(Text)<TextProps>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const ResourceCardDescription: React.FC<TextProps> = styled(Text)<TextProps>`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
