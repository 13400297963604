import React from 'react'
// Types
import { PaginationProps } from '../../../types/pages'
import { ResourceCardProps } from '../../../types/resources'
// Components
import { Row, Col } from 'react-styled-flexboxgrid'
import { Box, Text } from '../../../atoms'
import ResourceCard from '../ResourceCard'
import { Pagination } from '../../commons'

interface PaginatedResourcesProps extends PaginationProps {
  resources: ResourceCardProps[]
  prevLabel?: string
  nextLabel?: string
  downloadLabel?: string
}

const PaginatedResources: React.FC<PaginatedResourcesProps> = ({ resources, downloadLabel, ...pagination }) => {
  return (
    <Box as={'section'} mt={[48, 64]}>
      <Row>
        <Col xs={12}>
          {/* Resources */}
          {resources.length > 0 ? (
            <Row>
              {resources.map((resource: ResourceCardProps) => (
                <Col key={resource.id} xs={12} sm={6} md={4}>
                  <Box mb={[7, 8, 10]}>
                    <ResourceCard {...resource} downloadLabel={downloadLabel} />
                  </Box>
                </Col>
              ))}
            </Row>
          ) : (
            <Box minHeight={'400px'}>
              <Text fontSize={3} opacity={0.7} textAlign={'center'}>
                No resource
              </Text>
            </Box>
          )}
          {/* Pagination */}
          <Pagination {...pagination} />
        </Col>
      </Row>
    </Box>
  )
}
export default PaginatedResources
